import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import { Panel, PanelHeader, PanelHeaderBack,
	Group, FormLayout, FormStatus,
	FormItem, Input, Select, Textarea, Button } from '@vkontakte/vkui';
import axios from "axios";
import {API_URL} from "../settings";
//import {TextField} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ChipsSelect } from '@vkontakte/vkui/dist/unstable';
import bridge from "@vkontakte/vk-bridge";
import TextField from "@material-ui/core/TextField";

const Newsort = props => {
	const [sortName, setSortName] = useState('');
	const [sortDescription, setSortDescription] = useState('');
	const [sortSelectioner, setSortSelectioner] = useState(null);
	const [sortYear, setSortYear] = useState(null);

	const [errorState, setErrorState] = useState(null);
	const [errorText, setErrorText] = useState(null);




	/*useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppStorageGetResult') {
				if (data.keys[0].key=="lastHybridizer") {
					opts.unshift((JSON.parse(data.keys[0].value)));
				}
			}
		});
		async function fetchData() {
			bridge.send("VKWebAppStorageGet", {"keys": ["lastHybridizer"]});
		}
		fetchData();
	}, []);
*/

	const sendData = function(e) {
		e.preventDefault();
		if (sortSelectioner && sortName && sortDescription) {
			var data = {};
			data['plant'] = 1;
			data['hybridizer'] = sortSelectioner.value;
			data['name'] = sortName;
			data['description'] = sortDescription;
			if (sortYear && sortYear>1900 && sortYear<2030) data['year_sel'] = sortYear;

			axios.post(`${API_URL}/varieties/`, data)
				.then(res => {
					if (res.data && res.data.slug) {
						//bridge.send("VKWebAppStorageSet", {"key": "lastHybridizer", "value": JSON.stringify({value:sortSelectioner, label: sortSelectionerLabel})});
						props.setCurrentVarietySlug(res.data.slug);
						props.setCurrentVarietyID(res.data.id);
						props.setCurrentVarietyName(res.data.name);
						props.setRefreshFlag(Math.random());
						props.setActivePanel("variety");
					} else {
						setErrorText("Ошибка записи");
						setErrorState(true);
					}

				})
				.catch (e => {
					setErrorText("Невозможно сохранить такой сорт");
					setErrorState(true);
				})
		}
	};
	const cleanError = function() {
		setErrorState(false);
		setErrorText(null);
	}
	var opts = props.hyb.length>0 && props.hyb.map(sel => ({value: sel.id, label: sel.name+(Boolean(sel.prefix)?' ('+([sel.prefix,sel.prefix2,sel.prefix3,sel.prefix4].filter(id=>Boolean(id)).join(', '))+')':'')}))
	return (
		<Panel id={props.id}>
			<PanelHeader
				left={<PanelHeaderBack onClick={props.goBack} data-to="home"/>}
			>
				Новый сорт
			</PanelHeader>
			<Group>
				{opts && <FormLayout onSubmit={sendData}>
					<FormItem>
						{errorState && <FormStatus header="Ошибка" mode="error">
							{errorText}
						</FormStatus>}
					</FormItem>
					<FormItem top="Селекционер" status={sortSelectioner ? 'valid' : 'error'}>
						<Autocomplete
						  id="combo-box-demo"
						  options={opts}
						  getOptionLabel={(option) => option.label || ''}
						  getOptionSelected={(option) => option.label}
						  renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Выберите селекционера" error={sortSelectioner===null}/>}
						  value={sortSelectioner}
						  openOnFocus
						  onChange={(e, newValue) => {
							  setSortSelectioner(newValue);
							  console.log(newValue)
						  }}

						/>
					</FormItem>
					<FormItem top="Название сорта" status={sortName ? 'valid' : 'error'} >
						<Input name="sortname" onChange={e => {setSortName(e.target.value); cleanError()}}/>
					</FormItem>


					<FormItem top="Описание сорта" status={sortDescription ? 'valid' : 'error'} >
						<Textarea name="sortdescription" onChange={e => setSortDescription(e.target.value)} />
					</FormItem>
					<FormItem top="Год представления сорта" status={((sortYear>1900 && sortYear<2030) || (sortYear==0) || (sortYear==null)) ? 'valid' : 'error'}>
						<Input name="sortyear" type='number' onChange={e => {setSortYear(e.target.value); cleanError()}}/>
					</FormItem>
					<FormItem>
						<Button size="l" stretched disabled={!sortName || !sortSelectioner || !sortDescription}>Сохранить</Button>
					</FormItem>
				</FormLayout>}
			</Group>


		</Panel>

	)

};

export default Newsort;
