import React from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, PanelHeaderBack, Div, SimpleCell, Avatar, Footer, Button, Group} from '@vkontakte/vkui';
import {BACKEND_URL} from "../settings";
import {Icon24Like} from "@vkontakte/icons";

const MyFitList = props => (
	<Panel id={props.id}>
		<PanelHeader
		>Моя коллекция
		</PanelHeader>
		<Div>
			{props.fetchedFitList &&
				props.fetchedFitList.map(curFit => (
					<SimpleCell
						before={<Avatar size={40} src={curFit.variety.previewphoto ? BACKEND_URL + '/' + curFit.variety.previewphoto : ''}/>}
						key={curFit.id}
						onClick={props.go}
						//data-tostory="home"
						data-to="variety"
						data-varietyid={curFit.variety.id}
						data-varietyslug={curFit.variety.slug}
						data-varietyname={curFit.variety.name}
						description={curFit.variety.hybridizer}
						style={{backgroundColor: "#00ff0008"}}
					>
						{curFit.variety.name}
					</SimpleCell>))
			}
			{props.fetchedFitList.length > 0 &&
			<Footer>
				<Div>
					{`Сортов в коллекции: ${props.fetchedFitList.length}`}
				</Div>
			</Footer>}
			{props.fetchedFitList.length === 0 &&
			<Footer>
				<Div>
					Список пока пуст.
				</Div>
				<Div>
					Для добавления сорта перейдите в реестр и нажмите на картинку галочки в карточке нужного сорта.
				</Div>

			</Footer>}
		</Div>

	</Panel>
);

export default MyFitList;