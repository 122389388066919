import React, {useEffect, useState} from 'react';
import bridge from '@vkontakte/vk-bridge';
import './variety.css'
import {
	Panel,
	PanelHeader,
	PanelHeaderBack,
	ScreenSpinner,
	SimpleCell, Cell,
	InfoRow,
	Gallery, Button, Div, FormItem, Group, File, FormLayoutGroup, Spinner, Snackbar, Avatar, PanelHeaderContent,
} from '@vkontakte/vkui';
import axios from "axios";
import {API_URL} from "../settings";
import {
	Icon20LikeOutline, Icon20Like, Icon24ErrorCircle, Icon24ClockOutline,
	Icon24CameraOutline, Icon24CheckCircleOn, Icon24CheckCircleOutline,
	Icon24DeleteOutline, Icon24Favorite, Icon24FavoriteOutline,
	Icon24Share,
	Icon24ShareOutline
} from "@vkontakte/icons";

const Variety = props => {
	const [currentVariety, setCurrentVariety] = useState(null);
	const [currentVarietyPhotos, setCurrentVarietyPhotos] = useState(null);
	const [slideNum, setSlideNum] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showErrorSnackbarText, setShowErrorSnackbarText] = useState('');

	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [showSuccessSnackbarText, setShowSuccessSnackbarText] = useState('');


	useEffect(() => {
		if (props.token) fetchCurrentVariety();
	}, [props.currentVarietySlug, props.token]);

	useEffect(() => {
		if (props.token) fetchCurrentVarietyPhotos();
	}, [currentVariety]);


	const fetchCurrentVariety = async function () {
		try {
			let res = await axios.get(`${API_URL}/varieties/${props.currentVarietySlug}/`);
			if (res && res.data) {
				setCurrentVariety(res.data);
				props.setPopout(null);
			}
		} catch {
			setShowErrorSnackbarText("Ошибка доступа к сети");
			setShowErrorSnackbar(true);
		}

	};

	const fetchCurrentVarietyPhotos = async function () {
		if (currentVariety && currentVariety.id)
		{
			try {
				let res = await axios.get(`${API_URL}/varietyphotos/?variety=${currentVariety.id}`)
				if (res && res.data && res.data.results) setCurrentVarietyPhotos(res.data.results);
			} catch {
				setShowErrorSnackbarText("Ошибка доступа к сети");
				setShowErrorSnackbar(true);
			}
		}

	};

	const sendfile = async function(e) {
		const fl = document.querySelector("input[type=file]").files[0];
		if (fl) {
			const formData = new FormData();
			setLoading(true);
			formData.append('personal_photo', true);
			formData.append('hybridizer_photo', false);
			formData.append('variety', currentVariety.id);
			formData.append('photo', fl);

			try {
				let res = await axios.post(`${API_URL}/varietyphotos/`, formData, {headers:{ 'Content-Type': 'multipart/form-data' }})
				await fetchCurrentVarietyPhotos();
				props.setRefreshFlag(Math.random());
			} catch {
				setShowErrorSnackbarText("Ошибка добавления фото");
				setShowErrorSnackbar(true);
			} finally {
				setLoading(false);
			}

		}

	};

	const picView = function() {
		try {
			if (bridge && bridge.supports("VKWebAppShowImages") && currentVarietyPhotos && currentVarietyPhotos.length>0) {
				bridge.send("VKWebAppShowImages", {
					images: currentVarietyPhotos.map(im=>im.photo_big),
					start_index: slideNum
					}
				);
			}
		} catch {
			setShowErrorSnackbarText("Ошибка показа фото");
			setShowErrorSnackbar(true);
		}

	}

	let varietyName = (currentVariety && currentVariety.name) ? currentVariety.name : props.currentVarietyName ? props.currentVarietyName : "";

	const shareClick = function() {
		if (bridge && bridge.supports("VKWebAppShare") && currentVariety && currentVariety.slug) {
			bridge.send("VKWebAppShare", {"link": `https://vk.com/app7866714#s=${currentVariety.slug}`});
		}
		else {
			setShowErrorSnackbarText("Ошибка с доступностью функции");
			setShowErrorSnackbar(true);
		}
	}

	const myWishClick = async function() {
		if (currentVariety.in_my_wish === true) {
			try {
				let res= await axios.delete(`${API_URL}/wishes/${currentVariety.my_wish_id}/`);
				if (res) {
					setCurrentVariety ({...currentVariety, in_my_wish: false, my_wish_id: 0});
					props.setRefreshFlagWishList(Math.random());
					setShowSuccessSnackbarText("Сорт удален из списка хотелок");
					setShowSuccessSnackbar(true);
				}
			} catch {
				setShowErrorSnackbarText("Ошибка удаления сорта");
				setShowErrorSnackbar(true);
			}

		} else {
			try {
				let res= await axios.post(`${API_URL}/wishes/`, {variety: currentVariety.id});
				if (res) {
					setCurrentVariety ({...currentVariety, in_my_wish: true, my_wish_id: res.data.id});
					props.setRefreshFlagWishList(Math.random());
					setShowSuccessSnackbarText("Сорт добавлен в список хотелок");
					setShowSuccessSnackbar(true);
				}
			} catch {
				setShowErrorSnackbarText("Ошибка добавления сорта");
				setShowErrorSnackbar(true);
			}
		}

	}

	const myFitClick = async function() {
		if (currentVariety.in_my_fit === true) {
			try {
				let res= await axios.delete(`${API_URL}/fits/${currentVariety.my_fit_id}/`);
				if (res) {
					setCurrentVariety ({...currentVariety, in_my_fit: false, my_fit_id: 0});
					props.setRefreshFlagFitList(Math.random());
					setShowSuccessSnackbarText("Сорт удален из коллекции");
					setShowSuccessSnackbar(true);
				}
			} catch {
				setShowErrorSnackbarText("Ошибка удаления сорта");
				setShowErrorSnackbar(true);
			}
		} else {
			try {
				let res= await axios.post(`${API_URL}/fits/`, {variety: currentVariety.id, fitgroup:7});
				if (res) {
					setCurrentVariety ({...currentVariety, in_my_fit: true, my_fit_id: res.data.id});
					props.setRefreshFlagFitList(Math.random());
					setShowSuccessSnackbarText("Сорт добавлен в коллекцию");
					setShowSuccessSnackbar(true);
				}
			} catch {
				setShowErrorSnackbarText("Ошибка добавления сорта");
				setShowErrorSnackbar(true);
			}
		}

	}

	const picLikeClick = async function() {
		if (slideNum>-1 && slideNum<currentVarietyPhotos.length && currentVarietyPhotos[slideNum].liked === false) {
			try {
				let res= await axios.post(`${API_URL}/varietyphotolikes/`, {varietyphoto: currentVarietyPhotos[slideNum].id});
				if (res) {
					let tF = JSON.parse(JSON.stringify(currentVarietyPhotos));
					tF[slideNum].liked=true;
					tF[slideNum].totallikes++;
					setCurrentVarietyPhotos(tF);
				}
			} catch {
				setShowErrorSnackbarText("Ошибка лайка");
				setShowErrorSnackbar(true);
			}

		}

	}

	const myPhotoDelete = async function() {
		console.log(currentVarietyPhotos[slideNum]);
		try {
			let res = await axios.delete(`${API_URL}/varietyphotos/${currentVarietyPhotos[slideNum].id}/`);
			await fetchCurrentVarietyPhotos();
			props.setRefreshFlag(Math.random());
		} catch {
			setShowErrorSnackbarText("Ошибка удаления фото");
			setShowErrorSnackbar(true);
		} finally {
			setLoading(false);
		}
	}

	return (
		<Panel id={props.id}>
			{currentVariety && !currentVariety.accepted &&
			<PanelHeader
				left={<PanelHeaderBack onClick={props.goBack} data-to="home_home"/>}
			>
				<PanelHeaderContent
					status="сорт ожидает проверки"
					before={<Icon24ClockOutline/>}
				>
					{varietyName}
				</PanelHeaderContent>
			</PanelHeader>}
			{(!currentVariety || (currentVariety && currentVariety.accepted)) &&
			<PanelHeader
				left={<PanelHeaderBack onClick={props.goBack} data-to="home_home"/>}
			>
				{varietyName}
			</PanelHeader>}
			{currentVariety &&
			<div>

				{currentVarietyPhotos && currentVarietyPhotos.length > 0 &&
				<Gallery
					slideWidth="100%"
					align="center"
					bullets="dark"
					showArrows
					draggable
					onChange={num=>setSlideNum(num)}
					className="myGal"
				>
					{currentVarietyPhotos && currentVarietyPhotos.length > 0 && currentVarietyPhotos.map(curPhoto => (
						<div key={curPhoto.id} style={{position:"relative"}}>
							<div className='img_div'>
								<img src={curPhoto.photo_big} onClick={picView} className='img_div_img'/>
							</div>
							<div style={{position: "absolute", top: '15px', right: '15px'}}>
								<Button mode="secondary" before={curPhoto.liked?<Icon20Like/>:<Icon20LikeOutline/>}
										style={{ padding: '0 10px' }}
										onClick={picLikeClick}
										className='likeButton'
								>{curPhoto.totallikes>0 && <span style={{marginLeft:'3px'}}>{curPhoto.totallikes}</span>}</Button>
							</div>
						</div>
					))}
				</Gallery>}
				<FormLayoutGroup mode="horizontal">
					<FormItem style={{ display: 'flex', justifyContent: 'flex-start'  }}>
						<Button mode="secondary" before={<Icon24ShareOutline/>} style={{ marginRight: '5px', padding: '0 5px'}} onClick={shareClick}>Поделиться</Button>
						<Button mode="secondary" before={currentVariety.in_my_wish?<Icon24Favorite/>:<Icon24FavoriteOutline/>}
								style={{ marginRight: '5px', padding: '0 3px' }}
								onClick={myWishClick}
						></Button>
						<Button mode="secondary" before={currentVariety.in_my_fit?<Icon24CheckCircleOn/>:<Icon24CheckCircleOutline/>}
								style={{ marginRight: '5px', padding: '0 3px' }}
								onClick={myFitClick}
						></Button>
						{loading && <Spinner size="regular" style={{ marginLeft: 'auto' }} />}
						{!loading &&<File mode="secondary" before={<Icon24CameraOutline/>} onChange={sendfile}
							  data-varietyid={currentVariety.id} style={{ marginLeft: 'auto', padding: '0 4px' }}>+
						</File>}
						{!loading &&
						currentVarietyPhotos && currentVarietyPhotos[slideNum] &&
						currentVarietyPhotos[slideNum].accepted === false &&
						currentVarietyPhotos[slideNum].can_delete &&
							<Button mode="secondary" before={<Icon24DeleteOutline/>}
									style={{ marginLeft: '5px', padding: '0 4px' }}
									onClick={myPhotoDelete}
							></Button>}
					</FormItem>
				</FormLayoutGroup>
				{currentVariety.name &&
				<Div>
					<InfoRow header="Название сорта">
						{currentVariety.name}
					</InfoRow>
				</Div>
				}
				{currentVariety.hybridizer &&
				<Div>
					<InfoRow header="Селекционер">
						{currentVariety.hybridizer.name}
					</InfoRow>
				</Div>
				}
				{currentVariety.description &&
				<Div>
					<InfoRow header="Описание сорта">
						{currentVariety.description}
					</InfoRow>
				</Div>
				}
				{currentVariety.year_sel &&
				<Div>
					<InfoRow header="Год представления сорта">
						{currentVariety.year_sel}
					</InfoRow>
				</Div>
				}
				{currentVarietyPhotos && currentVarietyPhotos.length > 0 &&
				<Div>
					<InfoRow header="Автор фото">
						{currentVarietyPhotos && currentVarietyPhotos[slideNum] && currentVarietyPhotos[slideNum].created_by.display_name}
					</InfoRow>
				</Div>
				}
			</div>

			}
			{!currentVariety && <ScreenSpinner size='large'/>}
			{showErrorSnackbar &&
			<Snackbar
				duration = "1500"
				onClose={() => setShowErrorSnackbar(false)}
				before={<Avatar size={24} ><Icon24ErrorCircle fill="#e64646" /></Avatar>}
			  >
				{showErrorSnackbarText}
			</Snackbar>}
			{showSuccessSnackbar &&
			<Snackbar
				duration = "1500"
				onClose={() => setShowSuccessSnackbar(false)}
				before={<Avatar size={24} ><Icon24CheckCircleOn fill="#00ff00" /></Avatar>}
			  >
				{showSuccessSnackbarText}
			</Snackbar>}
		</Panel>
	);
}
export default Variety;