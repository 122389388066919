import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import {
	Panel,
	PanelHeader,
	Header,
	Button,
	Group,
	Cell,
	Div,
	Avatar,
	Search,
	SimpleCell,
	Footer,
	FixedLayout,
	PanelHeaderButton, PanelHeaderBack, ScreenSpinner, Snackbar
} from '@vkontakte/vkui';
import {API_URL, BACKEND_URL} from "../settings";
import Icon28AddOutline from "@vkontakte/icons/dist/28/add_outline";
import Icon24Filter from "@vkontakte/icons/dist/24/filter";
import axios from "axios";
import {Icon24CheckCircleOn, Icon24ErrorCircle} from "@vkontakte/icons";



const Home = (props) => {
	const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
	const [showErrorSnackbarText, setShowErrorSnackbarText] = useState('');

	const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
	const [showSuccessSnackbarText, setShowSuccessSnackbarText] = useState('');
	return (
	<Panel id={props.id}>
		<PanelHeader separator={false}>
			FITOLAND
		</PanelHeader>

		{props.fetchedVarieties &&
		<Group>
			{/*<Group header={<Header mode="secondary">Sorts Data</Header>}>*/}
			<FixedLayout vertical="top">
				{true && <Div style={{paddingTop: 0, paddingLeft: 0}}><Search value={props.searchString} onChange={e => props.setSearch(e.target.value)} icon={<Icon24Filter/>}
						onIconClick={props.activateFiltersModal} placeholder="Введите название сорта"/></Div>}
			</FixedLayout>
			<Div style={{paddingTop: 40}}>
				{props.fetchedVarieties.length > 0 && props.fetchedVarieties.map((curVariety) => (
					<SimpleCell
						before={<Avatar size={40}
										src={curVariety.previewphoto ? BACKEND_URL + '/' + curVariety.previewphoto : ''}/>}
						key={curVariety.id}
						onClick={props.go}
						data-to="variety"
						data-varietyid={curVariety.id}
						data-varietyslug={curVariety.slug}
						data-varietyname={curVariety.name}
						description={curVariety.hybridizer}
					>{curVariety.name}</SimpleCell>
				))}
			</Div>
			{props.nextPage && <Div><Button stretched onClick={props.fetchMoreVarieties}>Еще</Button></Div>}
			{props.fetchedVarieties.length === 0 &&
			<Footer>
				<Div>
					Ничего не найдено
				</Div>
				<Div>
					<Button size="l" mode="secondary" onClick={props.go} data-to="newsort">
						Добавить сорт
					</Button>
				</Div>

			</Footer>}

		</Group>}
		{showErrorSnackbar &&
			<Snackbar
				duration = "1500"
				onClose={() => setShowErrorSnackbar(false)}
				before={<Avatar size={24} ><Icon24ErrorCircle fill="#e64646" /></Avatar>}
			  >
				{showErrorSnackbarText}
			</Snackbar>}
			{showSuccessSnackbar &&
			<Snackbar
				duration = "1500"
				onClose={() => setShowSuccessSnackbar(false)}
				before={<Avatar size={24} ><Icon24CheckCircleOn fill="#00ff00" /></Avatar>}
			  >
				{showSuccessSnackbarText}
			</Snackbar>}
	</Panel>)
};


export default Home;
