import React, { useState, useEffect } from 'react';
import axios from 'axios';
import bridge from '@vkontakte/vk-bridge';
import {
	Epic,
	Tabbar,
	TabbarItem,
	View,
	Group,
	FormItem,
	Radio,
	SelectMimicry,
	viewWidth,
	ScreenSpinner,
	AdaptivityProvider,
	AppRoot,
	ModalRoot,
	ModalPage,
	ModalPageHeader,
	PanelHeaderClose,
	PanelHeaderSubmit,
	ConfigProvider,
	SplitLayout, SplitCol,
	useAdaptivity, usePlatform, withAdaptivity
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Home from './panels/Home';
import Newsort from './panels/Newsort';
import Variety from './panels/Variety';
import {API_URL} from "./settings";
import Icon28UserCircleOutline from "@vkontakte/icons/dist/28/user_circle_outline";
import Icon28LikeOutline from "@vkontakte/icons/dist/28/like_outline";
import Icon28ListOutline from "@vkontakte/icons/dist/28/list_outline";
import Icon28CheckCircleOutline from "@vkontakte/icons/dist/28/check_circle_outline";
import Profile from "./panels/Profile";
import MyWishList from "./panels/MyWishList";
import MyFitList from "./panels/MyFitList";
import AddList from "./panels/AddList";
import {Icon28FavoriteOutline} from "@vkontakte/icons";
import AuthHome from "./panels/AuthHome";
import Redir from "./panels/Redir";

function App ({ viewWidth }) {
	const [activeStory, setActiveStory] = useState('redir');
	const onStoryChange = (e) => {
		/*if (token) {
			setActiveStory(e.currentTarget.dataset.story);
			setActivePanel("home");
			setHistory(["home"]);
		}*/

	};

	const [running, setRunning] = useState(null);
	const [history, setHistory] = useState(['home']);
	const [activePanel, setActivePanel] = useState('home');
	const [fetchedUser, setUser] = useState(null);
	const [popout, setPopout] = useState(<ScreenSpinner size='large' />);
	const [token, setToken] = useState(null);
	const [refreshFlag, setRefreshFlag] = useState(null);
	const [refreshFlagWishList, setRefreshFlagWishList] = useState(null);
	const [refreshFlagFitList, setRefreshFlagFitList] = useState(null);
	const [fetchedHybridizers, setHybridizers] = useState(null);
	const [searchString, setSearch] = useState('');
	const [fetchedVarieties, setVarieties] = useState(null);
	const [fetchedWishList, setWishList] = useState(null);
	const [fetchedFitList, setFitList] = useState(null);
	const [nextPage, setNextPage] = useState(null);
	const [startParams, setStartParams] = useState(window.location.search);
	const [platform, setPlatform] = useState(null);

	const [currentVarietyID, setCurrentVarietyID] = useState(null);
	const [currentVarietyName, setCurrentVarietyName] = useState(null);
	const [currentVarietySlug, setCurrentVarietySlug] = useState(null);


	const [activeModal, setActiveModal] = useState(null);
	const [filterOrdering, setFilterOrdering] = useState("-id");
	const [filterExtended, setFilterExtended] = useState(false);


	//const { viewWidth } = useAdaptivity();
	//console.log(useAdaptivity())
  	//const isMobile = viewWidth <= ViewWidth.MOBILE;
  	//const platform = usePlatform();

	useEffect(() => {
		let ha = window.location.hash.slice(1)
		if (ha.length>0) {
			let k = ha.split('=')[0];
			if (k=="addlist") {
				setActiveStory('home')
				setActivePanel('addlist')
			}
			if (k=='s') {
				let slug = ha.split('=')[1];
				if (slug && slug.length>0) {
					setCurrentVarietySlug(slug);
					setHistory(["home", "variety"]);
					setActivePanel('variety');
					bridge.send('VKWebAppDisableSwipeBack');
				}
				else {
					setActivePanel('home');
					bridge.send('VKWebAppEnableSwipeBack');
				}
			}

		}
	}, []);

	useEffect(() => {
		bridge.subscribe(({ detail: { type, data }}) => {
			if (type === 'VKWebAppUpdateConfig') {
				const schemeAttribute = document.createAttribute('scheme');
				schemeAttribute.value = data.scheme ? data.scheme : 'client_light';
				document.body.attributes.setNamedItem(schemeAttribute);
			}
		});
	}, []);

	useEffect(() => {
			async function fetchData() {
			const user = await bridge.send('VKWebAppGetUserInfo');
			setUser(user);
		}
		fetchData();
	}, [token]);
	useEffect(() => {
		async function fetchToken() {
			const params = (new URL(window.location)).searchParams;
			const code = params.get('code');
			const vk_app_id = params.get('vk_app_id');
			const vk_platform = params.get('vk_platform');
			let link=''
			let data={}
			if (code) {
				link = '/vk/';
				data={code: code}
				setRunning('web');
			}
			if (vk_app_id==='7866714') {
				link = '/vkmini/';
				data={access_token: window.location.search}
				setRunning('vkmini');
				setStartParams(window.location.search)
				setPlatform(vk_platform);
			}
			if (link) {
				axios.post(`${API_URL}${link}`, data)
					.then(res => {
						if (res && res.data && res.data.key) {
							setToken(res.data.key)
							console.log("token set");
							axios.defaults.headers.common = {Authorization: `Token ${res.data.key}`}
							fetchHybridizers();
							//setPopout(null);
						}
					})
					.catch(e => {
						setActivePanel('authhome');
						console.log("can't get token");
						setPopout(null);
					})
			} else {
				setActivePanel('authhome');
				console.log("can't get token");
				setPopout(null);
			}

		}
		fetchToken();
	}, []);

	let isCooldown = false;
	useEffect(() => {
		if (!isCooldown) {
			isCooldown = true;
			if (token) fetchVarieties();
			setTimeout(() => isCooldown = false, 500);
		}
	}, [token, searchString, filterOrdering, filterExtended, refreshFlag]);

	useEffect(() => {
		if (token) fetchWishList();
	}, [token, refreshFlagWishList]);

	useEffect(() => {
		if (token) fetchFitList();
	}, [token, refreshFlagFitList]);

	async function fetchVarieties() {
		axios.get(`${API_URL}/varieties/?plant__slug=senpoliya-uzambarskaya-fialka&ordering=${filterOrdering}&extended=${filterExtended}&search=${searchString}`)
			.then(res => {
				if (res && res.data && res.data.results) {
					setVarieties(res.data.results);
					setNextPage(res.data.next);
					setPopout(null);
				}
			})
			.catch(e => {
				console.log("can't get varts");
			})
	}

	async function fetchMoreVarieties() {
		if (nextPage) {
			axios.get(nextPage)
				.then(res => {
					if (res && res.data && res.data.results) {
						setVarieties(fetchedVarieties.concat(res.data.results));
						setNextPage(res.data.next);
						setPopout(null);
					}

				})
				.catch(e => {
					console.log("can't get more varts");
				})
		}
	}

	async function fetchWishList() {
		axios.get(`${API_URL}/wishes/?limit=300`)
			.then(res => {
				if (res && res.data && res.data.results) {
					setWishList(res.data.results);
				}

			})
			.catch(e => {
				console.log("can't get fits");
			})
	}

	async function fetchFitList() {
		axios.get(`${API_URL}/fits/?limit=300`)
			.then(res => {
				if (res && res.data && res.data.results) {
					setFitList(res.data.results);
				}

			})
			.catch(e => {
				console.log("can't get fits");
			})
	}


	async function fetchHybridizers() {
		axios.get(`${API_URL}/hybridizers/?limit=100`)
			.then(res => {
				if (res && res.data && res.data.results) setHybridizers(res.data.results);
			})
			.catch(e => {
				console.log("can't get hybridizers");
			})
	}






	const go = e => {
		if (e.currentTarget.dataset.varietyid) {
			if (e.currentTarget.dataset.varietyid !== currentVarietyID) {
				setCurrentVarietyID(e.currentTarget.dataset.varietyid);
				setCurrentVarietySlug(e.currentTarget.dataset.varietyslug);
				setCurrentVarietyName(e.currentTarget.dataset.varietyname);
			}
		}

		let hist = [...history];
		hist.push(e.currentTarget.dataset.to);
		setHistory(hist);
		bridge.send('VKWebAppDisableSwipeBack');
		setActivePanel(e.currentTarget.dataset.to);
	};

	const goBack = e => {
		setHistory(["home"]);
		setActivePanel("home");
		bridge.send('VKWebAppEnableSwipeBack');
	}

	const activateFiltersModal = function () {
		setActiveModal('filters')
	};
	const closeFiltersModal = function () {
		setActiveModal(null)
	};


	const modal = (
      <ModalRoot
        activeModal={activeModal}
        onClose={closeFiltersModal}
      >
        <ModalPage
          id='filters'
          onClose={closeFiltersModal}
          header={
            <ModalPageHeader
              left={true && <PanelHeaderClose onClick={closeFiltersModal}/>}
              right={<PanelHeaderSubmit onClick={closeFiltersModal}/>}
            >
              Фильтры
            </ModalPageHeader>
          }
        >
          <Group>
            <FormItem top="Область поиска">
              <Radio name="extended" value={0} checked={filterExtended===false} onChange={e=>setFilterExtended(false)}>Только в названиях</Radio>
              <Radio name="extended" value={1} checked={filterExtended===true} onChange={e=>setFilterExtended(true)}>Везде</Radio>

            </FormItem>

			<FormItem top="Сортировка">
              <Radio name="sort" value={'-id'} checked={filterOrdering==='-id'} onChange={e=>setFilterOrdering('-id')}>По дате добавления</Radio>
              <Radio name="sort" value={'name'} checked={filterOrdering==='name'} onChange={e=>setFilterOrdering('name')}>По алфавиту</Radio>
            </FormItem>

          </Group>
        </ModalPage>



      </ModalRoot>
    );


	return (
		<ConfigProvider>
			<AdaptivityProvider>
				<AppRoot>
					<SplitLayout>
            			<SplitCol width="280px">
							<Epic activeStory={activeStory} tabbar={
							  <Tabbar style={{maxWidth: "1024px", marginLeft: "auto", left:"auto"}}>
								<TabbarItem
								  onClick={onStoryChange}
								  selected={activeStory === 'home'}
								  data-story="home"
								  text="Реестр"
								><Icon28ListOutline /></TabbarItem>
								<TabbarItem
								  onClick={onStoryChange}
								  selected={activeStory === 'wishes'}
								  data-story="wishes"
								  text="Хотелки"
								><Icon28FavoriteOutline/></TabbarItem>
								<TabbarItem
								  onClick={onStoryChange}
								  selected={activeStory === 'fits'}
								  data-story="fits"
								  text="Коллекция"
								><Icon28CheckCircleOutline/></TabbarItem>
								<TabbarItem
								  onClick={onStoryChange}
								  selected={activeStory === 'profile'}
								  data-story="profile"
								  text="Профиль"
								><Icon28UserCircleOutline /></TabbarItem>
							  </Tabbar>
							}>
								<View id="home" activePanel={activePanel} popout={popout} modal={modal} onSwipeBack={goBack} history={history}>
									<AuthHome id='authhome'/>
									<Home id='home' go={go} popout={popout} token={token} fetchedVarieties={fetchedVarieties} nextPage={nextPage} fetchMoreVarieties={fetchMoreVarieties} activateFiltersModal={activateFiltersModal} filterOrdering={filterOrdering} filterExtended={filterExtended} refreshFlag={refreshFlag} searchString={searchString} setSearch={setSearch}/>
									<Newsort id='newsort' go={go} goBack={goBack} hyb={fetchedHybridizers} setActivePanel={setActivePanel} setCurrentVarietySlug={setCurrentVarietySlug} setCurrentVarietyName={setCurrentVarietyName} setCurrentVarietyID={setCurrentVarietyID}  setRefreshFlag={setRefreshFlag}/>
									<Variety id='variety' go={go} goBack={goBack} setPopout={setPopout} fetchedUser={fetchedUser} token={token} currentVarietyID={currentVarietyID} currentVarietyName={currentVarietyName} currentVarietySlug={currentVarietySlug}  setRefreshFlag={setRefreshFlag} setRefreshFlagWishList={setRefreshFlagWishList} setRefreshFlagFitList={setRefreshFlagFitList}/>
									<AddList id='addlist' hyb={fetchedHybridizers} />
								</View>
								<View id="profile" activePanel={activePanel} popout={popout}>
									<Profile id='home' fetchedUser={fetchedUser} go={go} startParams={startParams} platform={platform}/>
								</View>
								<View id="wishes" activePanel={activePanel} popout={popout} onSwipeBack={goBack} history={history}>
									<MyWishList id='home' fetchedWishList={fetchedWishList} go={go}/>
									<Variety id='variety' go={go} goBack={goBack} setPopout={setPopout} fetchedUser={fetchedUser} token={token} currentVarietyID={currentVarietyID} currentVarietyName={currentVarietyName} currentVarietySlug={currentVarietySlug}  setRefreshFlag={setRefreshFlag} setRefreshFlagWishList={setRefreshFlagWishList} setRefreshFlagFitList={setRefreshFlagFitList}/>
								</View>
								<View id="fits" activePanel={activePanel} popout={popout} onSwipeBack={goBack} history={history}>
									<MyFitList id='home' fetchedFitList={fetchedFitList} go={go}/>
									<Variety id='variety' go={go} goBack={goBack} setPopout={setPopout} fetchedUser={fetchedUser} token={token} currentVarietyID={currentVarietyID} currentVarietyName={currentVarietyName} currentVarietySlug={currentVarietySlug}  setRefreshFlag={setRefreshFlag} setRefreshFlagWishList={setRefreshFlagWishList} setRefreshFlagFitList={setRefreshFlagFitList}/>
								</View>
								<View id="redir" activePanel='home' popout={popout}>
									<Redir id='home' fetchedUser={fetchedUser} go={go} startParams={startParams} platform={platform}/>
								</View>
							</Epic>
						</SplitCol>

					</SplitLayout>
				</AppRoot>
				</AdaptivityProvider>
		</ConfigProvider>

	);
}
App = withAdaptivity(App, { viewWidth: true });

export default App;
