import React from 'react';
import PropTypes from 'prop-types';

import {Avatar, Cell, Group, Header, Panel, PanelHeader, SimpleCell, CellButton, Button} from '@vkontakte/vkui';
import {APP_VERSION} from "../settings";
import {
	Icon28ChevronRightOutline,
	Icon28GhostOutline, Icon28LinkCircleOutline,
	Icon28ServicesOutline,
	Icon36ServicesOutline
} from "@vkontakte/icons";

const Redir = props => (
	<Panel id={props.id}>
		<PanelHeader
		>FITOLAND
		</PanelHeader>
			{(props.platform === "desktop_web" || props.platform === "mobile_web" )&&
			<Button style={{ marginTop: '100px' }}
                onClick={e=>window.open("https://fito.land/authcallbackvkmini"+props.startParams, '_blank')}>
				Запустить программу
			</Button>}

			{props.platform !== "desktop_web" && props.platform !== "mobile_web" &&
			<Button  style={{ marginTop: '100px' }}
                         href={"https://fito.land/authcallbackvkmini"+props.startParams} target='_blank'>
				Запустить программу
			</Button>}
	</Panel>
);

export default Redir;