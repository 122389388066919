import React from 'react';

import { Avatar, Cell, Group, Header, Panel, PanelHeader, SimpleCell, CellButton, Button} from '@vkontakte/vkui';
import {APP_VERSION} from "../settings";
import {
	Icon28ChevronRightOutline,
	Icon28GhostOutline, Icon28LinkCircleOutline,
	Icon28ServicesOutline,
	Icon36ServicesOutline
} from "@vkontakte/icons";

const AuthHome = props => {

    return (
        <Panel id={props.id}>
            <PanelHeader
            >FITOLAND - вход
            </PanelHeader>

            <Group header={<Header mode="secondary">Выберите вариант авторизации</Header>}>

                <SimpleCell
                    before={<Icon28ServicesOutline/>}
                >
                    <Button
                        href={`https://oauth.vk.com/authorize?client_id=7877929&display=page&redirect_uri=${window.location.protocol}//${window.location.hostname}&response_type=code&v=5.126`}
                    >Войти через ВКонтакте</Button>
                </SimpleCell>

            </Group>


        </Panel>
    );
}
export default AuthHome;