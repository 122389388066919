import React, {useState} from 'react';

import { Panel, PanelHeader, PanelHeaderBack,
	Group, FormLayout, FormStatus, Div,
	FormItem, Input, Select, Textarea, Button } from '@vkontakte/vkui';
import axios from "axios";
import {API_URL} from "../settings";

const AddList = props => {
	const [sortName, setSortName] = useState('');
	const [sortDescription, setSortDescription] = useState('');
	const [sortSelectioner, setSortSelectioner] = useState('');
	const [errorState, setErrorState] = useState(null);
	const [errorText, setErrorText] = useState(null);
	const [lines, setLines] = useState(null);

	const splitSorts = async function(e) {
		console.log(e.target.value);
		let arr = e.target.value.split("\n").filter(l=>l.length>1);
		console.log(arr);
		var data = {};
		for (let line of arr) {
			//console.log(line);
			let vals = line.split(";");
			if (vals && vals.length>0) {
				console.log(vals)
				data = {}
				data['plant'] = 1;
				data['name'] = vals[0];
				data['hybridizer'] = sortSelectioner;
				data['description'] = vals[1];
				if (vals[2]>0) data['year_sel'] = vals[2];
				if (data['name'] && data['hybridizer'] && data['description'])
				try {
					await axios.post(`${API_URL}/varieties/`, data);
					console.log("success "+data['name']);
				} catch {
					console.log("fail "+data['name']);
				}


			}
		}


		//setLines(arr);
		// getLine();
	}

	const getLine = function() {
		setSortName(lines[0].name);
	}

	const sendData = function(e) {
		e.preventDefault();
		var data = {};
		data['plant'] = 1;
		data['hybridizer'] = sortSelectioner;
		data['name'] = sortName;
		data['description'] = sortDescription;
		axios.post(`${API_URL}/varieties/`, data)
			.then(res => {
				if (res.data && res.data.slug) {
					getLine();
				} else {
					setErrorText("Ошибка записи");
					setErrorState(true);
				}

			})
			.catch (e => {
				setErrorText("Невозможно сохранить такой сорт");
				setErrorState(true);
			})

	};
	const cleanError = function() {
		setErrorState(false);
		setErrorText(null);
	}
	return (
		<Panel id={props.id}>
			<PanelHeader
				left={<PanelHeaderBack onClick={props.go} data-to="home"/>}
			>
				Новый сорт по списку
			</PanelHeader>
			{props.hyb && <Group>
				<FormLayout onSubmit={sendData}>
                    <FormItem>
                        {errorState && <FormStatus header="Ошибка" mode="error">
                            {errorText}
                        </FormStatus>}
                    </FormItem>
					<Div>
						{sortName}
					</Div>
					<Div>
						{sortDescription}
					</Div>
					<FormItem top="Селекционер" status={sortSelectioner ? 'valid' : 'error'}>
						<Select
							name="sortselectioner"
							placeholder="Выберите селекционера"
							options={props.hyb.map(sel => ({value: sel.id, label: sel.name+(Boolean(sel.prefix)?' ('+([sel.prefix,sel.prefix2,sel.prefix3,sel.prefix4].filter(id=>Boolean(id)).join(', '))+')':'')}))}

							onChange={e => setSortSelectioner(e.target.value)}
						/>
					</FormItem>
					<FormItem top="Список сортов">
						<Textarea name="sortList" onChange={splitSorts}/>
					</FormItem>
					<FormItem>
						<Button size="l" stretched onClick={sendData} disabled={sortSelectioner===""}>Сохранить</Button>
					</FormItem>
				</FormLayout>
			</Group>}


		</Panel>

	)

};

export default AddList;
