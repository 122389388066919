import React from 'react';
import PropTypes from 'prop-types';

import {Panel, PanelHeader, PanelHeaderBack, Div, SimpleCell, Avatar, Footer, Button, Group} from '@vkontakte/vkui';
import {BACKEND_URL} from "../settings";
import {Icon24Like} from "@vkontakte/icons";

const MyWishList = props => (
	<Panel id={props.id}>
		<PanelHeader>
			Мои хотелки
		</PanelHeader >
		<Div>
			{props.fetchedWishList &&
				props.fetchedWishList.map(curWish => (
					<SimpleCell
						before={<Avatar size={40} src={curWish.variety.previewphoto ? BACKEND_URL + '/' + curWish.variety.previewphoto : ''}/>}
						key={curWish.id}
						onClick={props.go}
						//data-tostory="home"
						data-to="variety"
						data-varietyid={curWish.variety.id}
						data-varietyslug={curWish.variety.slug}
						data-varietyname={curWish.variety.name}
						description={curWish.variety.hybridizer}
						style={{backgroundColor: "#ff000008"}}
					>
						{curWish.variety.name}
					</SimpleCell>))
			}
			{props.fetchedWishList.length > 0 &&
			<Footer>
				<Div>
					{`Сортов в хотелках: ${props.fetchedWishList.length}`}
				</Div>
			</Footer>}
			{props.fetchedWishList.length === 0 &&
			<Footer>
				<Div>
					Список пока пуст.
				</Div>
				<Div>
					Для добавления сорта перейдите в реестр и нажмите на картинку звездочки в карточке нужного сорта.
				</Div>

			</Footer>}
		</Div>

	</Panel>
);

export default MyWishList;