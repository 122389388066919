import React from 'react';
import PropTypes from 'prop-types';

import { Avatar, Cell, Group, Header, Panel, PanelHeader, SimpleCell, CellButton} from '@vkontakte/vkui';
import {APP_VERSION} from "../settings";
import {
	Icon28ChevronRightOutline,
	Icon28GhostOutline, Icon28LinkCircleOutline,
	Icon28ServicesOutline,
	Icon36ServicesOutline
} from "@vkontakte/icons";

const Profile = props => (
	<Panel id={props.id}>
		<PanelHeader

		>Мой профиль
		</PanelHeader>
		{props.fetchedUser &&
			<Group>
				<Cell
					before={props.fetchedUser.photo_200 ? <Avatar src={props.fetchedUser.photo_200}/> : null}
					description={props.fetchedUser.city && props.fetchedUser.city.title ? props.fetchedUser.city.title : ''}
				>
					{`${props.fetchedUser.first_name} ${props.fetchedUser.last_name}`}
				</Cell>

			</Group>}

		<Group header={<Header mode="secondary">О приложении</Header>}>

			<SimpleCell
				before={<Icon28ServicesOutline />}

				description="Версия приложения">

				{APP_VERSION}
			</SimpleCell>
			<SimpleCell
				before={<Icon28GhostOutline />}
				after={<Icon28ChevronRightOutline />}
				description="Разработчик"
				href="https://vk.com/fialki_sp"
				target="_blank"
				expandable={true}>
				fialki_sp
			</SimpleCell>
			<SimpleCell
				before={<Icon28LinkCircleOutline />}
				after={<Icon28ChevronRightOutline />}
				description="Напишите нам"
				href="https://vk.com/im?sel=-140940"
				target="_blank"
				expandable={true}>
				Задать вопрос или сообщить о проблеме
			</SimpleCell>
			{(props.platform === "desktop_web" || props.platform === "mobile_web" )&&
			<SimpleCell onClick={e=>window.open("https://fito.land/authcallbackvkmini"+props.startParams, '_blank')}>
				Открыть сайт
			</SimpleCell>}

			{props.platform !== "desktop_web" && props.platform !== "mobile_web" &&
			<SimpleCell href={"https://fito.land/authcallbackvkmini"+props.startParams} target='_blank'>
				Открыть сайт
			</SimpleCell>}

		</Group>



	</Panel>
);

export default Profile;